import { CouponId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";

export async function coupon__server__useCoupon(p: {
  couponId: CouponId;
}): Promise<{ status: "success" } | { status: "error"; message: string }> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  const coupon = await h.Coupon.getDoc(p.couponId);

  if (!coupon) {
    return { status: "error", message: `Could not find coupon ${p.couponId}.` };
  }

  if (coupon.useLimit && coupon.numberTimesUsed && coupon.numberTimesUsed >= coupon.useLimit) {
    return { status: "error", message: `Coupon ${p.couponId} has reached its use limit.` };
  }

  if (coupon.expirationDateMS && coupon.expirationDateMS < Date.now()) {
    return { status: "error", message: `Coupon ${p.couponId} has expired.` };
  }

  await h.Coupon.update({
    id: coupon.id,
    doc: {
      numberTimesUsed: (coupon.numberTimesUsed ?? 0) + 1
    }
  });

  return { status: "success" };
  // SERVER_ONLY_TOGGLE
}

coupon__server__useCoupon.auth = async (r: any) => {
  await validateToken(r);
};

// i18n certified - complete
