import { Coupon, CouponId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../../helpers";
import { validateToken } from "../../internal-utils/server-auth";

export async function coupon__server__getCoupon(p: { couponId: CouponId }): Promise<Coupon | null> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  return await h.Coupon.getDoc(p.couponId);
  // SERVER_ONLY_TOGGLE
}

coupon__server__getCoupon.auth = async (r: any) => {};

// i18n certified - complete
